import React, {useEffect} from 'react';

/* Styles */
import styles from './statuserrorpage.module.scss';

/* Logger */
import {Logger} from '@utils/logger';
import {NextSeo} from 'next-seo';

type PureErrorPageProps = {
  code: number;
  title?: string;
};

const StatusErrorPage = ({code, title = ''}: PureErrorPageProps) => {
  useEffect(() => {
    Logger('Error Page')(`${code}` || 'Cannot derive status code');
  }, []);

  return (
    <>
      <NextSeo title={`Error Page ${code}`} noindex nofollow />
      <div className="l-content-section">
        <div className={styles.container}>
          <div className={styles.signContainer}>
            <div className={styles.signTextContainer}>
              <img src="/_nextassets_/images/sign.png" className={styles.signImg} alt="Error Page" />
              <h1 className={styles.code}>
                <span>{code}</span>
                <br />
                error
              </h1>
              <h2 className={styles.errorText}>{title}</h2>
              <a href={process.env.NEXT_PUBLIC_HOST_URL_CLIENT} className={styles.takeMeHome}>
                Take Me Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusErrorPage;
